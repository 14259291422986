<template>
  <form-view
    :url="`/user/role/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
    :before-submit="beforeSubmit"
  >
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label-width="100px" label="角色名称" prop="roleName" :rules="{required: true,message:'必须输入角色名称'}">
          <el-input v-model="dataForm.roleName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="16">
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="dataForm.remarks"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">权限列表</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-tree
          :data="permissions"
          :props="{ label: 'permissionName', children: 'list' }"
          node-key="permissionId"
          ref="tree"
          show-checkbox
          style="margin-bottom: 20px;"
        ></el-tree>
      </el-col>
      <!-- <el-col :span="12">
        <el-tree
          :data="baseList"
          :props="{ label: 'name', children: 'list' }"
          node-key="id"
          ref="baseTree"
          show-checkbox
          style="margin-bottom: 20px;"
        ></el-tree>
      </el-col> -->
    </el-row>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'

export default {
  name: 'person-role-update',

  components: { FormView },

  data () {
    return {
      loading: false,
      dataForm: {},
      permissions: [],
      baseList: []
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/user/role/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = {
            ...data.datas,
            permissionIds: data.datas.permissions.map(p => p.permissionId)
          }
          this.$refs.tree.setCheckedKeys(this.dataForm.permissionIds)
          // this.$refs.baseTree.setCheckedKeys(this.dataForm.roleDepartmentList)
          this.loading = false
        }
      } else {
        this.dataForm = {
          roleName: '',
          remarks: '',
          permissionIds: [],
          roleDepartmentList: []
        }
        this.loading = false
      }
      let data = await this.$http({
        url: this.$http.adornUrl('/user/role/permission/list'),
        method: 'post'
      })
      this.permissions = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/user/base/list'),
        method: 'post'
      })
      this.baseList = data.datas
    },

    beforeSubmit (params) {
      params.permissionIds = this.$refs.tree.getCheckedKeys()
      // params.roleDepartmentList = this.$refs.baseTree.getCheckedKeys()
      return params
    }
  }
}
</script>
